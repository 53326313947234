<template>
  <v-dialog
    v-model="modal"
    width="600"
    :fullscreen="$vuetify.breakpoint.mobile"
    >
    <v-card>
      <v-card-title
        class="justify-center"
        >
        Crear promoción
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="modal = false"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert
          type="error"
          v-if="error"
          >
          Código ya se encuentra en uso.
        </v-alert>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          >
          <v-select
            :items="names"
            v-model="promotionType"
            label="Tipo de oferta"
            outlined
            ></v-select>
          <v-autocomplete
            v-if="promotionType == 'category'"
            :items="categories"
            v-model="attributes.promotionableId"
            :rules="[ v => !!v || 'Campo obligatorio']"
            item-text="fullName"
            item-value="id"
            label="Categoría"
            outlined
            ></v-autocomplete>
          <v-text-field
            label="Nombre"
            :disabled="promotionType == 'first_order'"
            v-model="attributes.name"
            outlined
            :rules="[ v => !!v || 'Campo obligatorio']"
            ></v-text-field>
          <v-text-field
            label="Código"
            v-if="promotionType == 'code'"
            v-model="attributes.code"
            outlined
            :rules="[ v => !!v || 'Campo obligatorio']"
            ></v-text-field>
          <div
            class="d-flex flex-row"
            >
            <v-select
              :items="discounts"
              v-model="attributes.promotionType"
              label="Tipo de descuento"
              :rules="[ v => !!v || 'Campo obligatorio']"
              outlined
              ></v-select>
            <div
              class="mx-2"
              ></div>
            <v-text-field
              label="Valor"
              v-model="attributes.value"
              type="number"
              outlined
              :suffix="attributes.promotionType == 'percent' ? '%' : '$'"
              :rules="[ 
                v => !!v || 'Campo obligatorio',
                v => attributes.promotionType == 'fixed_value' || (v || 0) > 0 && (v || 0) <= 100 || 'Debe ser mayor a 0 y menor o igual a 100' 
              ]"
              ></v-text-field>
          </div>

          <div
            class="text-right"
            >
            <v-btn
              tile
              color="success"
              @click="createPromotion"
              >
              Crear
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Categories } from '@/graphql/queries/categories'
import { CreatePromotion } from '@/graphql/mutations/promotions'

export default {
  data: () => ({
    modal: false,
    valid: true,
    firstOrder: false,
    names: [
      {
        text: 'Primera compra',
        value: 'first_order',
        disabled: false,
      },
      {
        text: 'Código de descuento',
        value: 'code'
      },
      /*
      {
        text: 'Categoría con descuento',
        value: 'category',
      },
      */
    ],
    discounts: [
      {
        text: 'Valor fijo',
        value: 'fixed_value'
      },
      {
        text: 'Porcentaje',
        value: 'percent'
      },
    ],
    categories: [],
    error: false,
    promotionType: 'first_order',
    attributes: {
      name: 'Primera compra',
      value: null,
      code: null,
      promotionableType: null,
      promotionableId: null,
      promotionType: 'fixed_value',
    }
  }),

  computed: {
    ...mapGetters(['currentUser'])
  },

  watch: {
    promotionType (val) {
      if (val == 'category') {
        this.attributes.promotionableType = 'Category'
        this.fetchCategories()
      } else if (val == "first_order") {
        this.attributes.name = 'Primera compra'
        this.attributes.promotionableType = null
        this.attributes.code = null
      }
    },
    
    firstOrder (val) {
      if (val) {
        this.names[0].disabled = true
        this.promotionType = 'code'
      }
    }
  },

  methods: {
    createPromotion () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: CreatePromotion,
          variables: {
            input: {
              attributes: this.attributes
            }
          }
        }).then ( res => {
          if (res.data.createPromotion.success) {
            this.$emit('reload')
            this.modal = false
          } else {
            this.error = true
          }
        }).catch ( err =>  {
          this.error = true
        })
      }
    },

    fetchCategories () {
      this.$apollo.query({
        query: Categories,
        variables: {
          storeId: this.currentUser.store.id,
          onlyRoot: true,
        }
      }).then ( res => {
        this.categories = res.data.categories
      })
    }
  },
}
</script>
